import { render, staticRenderFns } from "./AboutMeComp.vue?vue&type=template&id=480c1345&scoped=true&"
import script from "./AboutMeComp.vue?vue&type=script&lang=js&"
export * from "./AboutMeComp.vue?vue&type=script&lang=js&"
import style0 from "./AboutMeComp.vue?vue&type=style&index=0&id=480c1345&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480c1345",
  null
  
)

export default component.exports